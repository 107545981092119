/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

html {
    --mdc-checkbox-state-layer-size: 30px;
}
.mat-mdc-paginator-container {
    justify-content: end !important;
    padding-right: 0 !important;
}

.mat-mdc-form-field-infix {
    min-height: 44px !important;
}

.mdc-button {
    min-width: 120px !important;
}

.mat-mdc-outlined-button:not([disabled=true]) {
    border: 1.5px solid #E01919 !important;
}

.popup-heading {
    top: -24px;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
}

input[type='time']::-webkit-calendar-picker-indicator {
    background: url('../assets/icons/time.svg') no-repeat;
    background-size: 23px;
}

.timefield {
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1 !important;
    border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    height: 45px;
    margin-bottom: 20px;
    input {
        width: 100%;
        padding: 0 16px;
        height: 42px;
    }
}

// switch css
.mdc-switch__handle-track {
    transform: translate(60%, 0%);
    border-radius: 50%;
}

.mdc-switch__track {
    background-color: #ffffff;
    border-radius: 15px !important;
    height: 24px !important;
    border: 1px solid var(--ion-color-grey1);
}

.mat-mdc-slide-toggle .mdc-switch {
    width: var(--mdc-switch-track-width, 46px) !important;
}

.mdc-switch--checked .mdc-switch__handle {
    left: -1px !important;
}

.mdc-switch__handle {
    left: 3px !important;
    width: var(--mdc-switch-handle-width, 18px) !important;
    height: var(--mdc-switch-handle-height, 18px) !important;
}

.mdc-switch__icons svg,
.mdc-switch__ripple,
.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before,
.mdc-checkbox__ripple {
    display: none !important;
}

.mdc-switch__handle::before,
.mdc-switch__handle::after {
    background: #ffffff !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
    background: #E01919 !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background: #07BF30 !important;
}

.mat-button-toggle {
    background: #F5F5F5 !important;
    line-height: 45px;
    min-width: 135px;
}

.mat-button-toggle-group .mat-button-toggle.mat-button-toggle-checked {
    background-color: #E01919 !important;

    .mat-button-toggle-label-content {
        color: #ffffff !important;
    }
}

fuse-vertical-navigation-basic-item>.fuse-vertical-navigation-item-wrapper .fuse-vertical-navigation-item:not(.fuse-vertical-navigation-item-disabled).fuse-vertical-navigation-item-active {
    background-color: #DF1F26 !important;
    color: #ffffff !important;
}


.ng-select {
    min-height: 45px;
    border-radius: 4px;
    padding: 0 16px;
    border-radius: 6px;
    --tw-border-opacity: 1 !important;
    border: 1px solid rgb(203 213 225 / var(--tw-border-opacity)) !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    width: 100%;
    margin-bottom: 15px;
    font-size: 14px;

    input {
        color: rgba(0, 0, 0, 0.87);
    }

    .ng-placeholder {
        color: rgb(149 164 185 / var(--tw-border-opacity));
    }

    .ng-select-container {
        align-items: inherit;
        min-height: inherit;

        &::after {
            border: 0;
        }
    }

    .ng-value-container {
        padding: 0 !important;
        align-items: center !important;
        border: 0 !important;
    }
}

.ng-select.ng-select-opened .ng-placeholder,
.ng-select .ng-has-value .ng-placeholder,
.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-filtered .ng-select-container .ng-placeholder {
    display: none;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 23%;
}

.ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
    bottom: 14px;
    // left: 5px;
}

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
    color: rgba(var(--fuse-primary-rgb), var(--tw-border-opacity)) !important;
}

.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper {
    border-top: 0;
    bottom: 0px;
}

.ng-select .ng-arrow-wrapper {
    bottom: -8px;
}

.ng-dropdown-panel.ng-select-bottom {
    top: calc(100% - 0em);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: var(--mdc-theme-surface, #fff);
    color: var(--mdc-theme-on-surface, #000);
}

.ng-select.ng-select-opened {
    border-color: rgba(var(--fuse-primary-rgb), var(--tw-border-opacity)) !important;
}

.ng-option-label {
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 0 16px;

    &:hover {
        background: rgba(0, 0, 0, 0.04);
    }
}

.green-snackbar div {
    background: rgb(34 197 94) !important;
    color: white !important;
}

.red-snackbar div {
    background: #E01919 !important;
    color: white !important;
}

.ng-select .ng-spinner-loader {
    align-self: center;
}

img {
    width: auto;
}

.md-drppicker {
    width: max-content !important;
    font-family: inherit !important;

    th,
    td {
        color: #00000099 !important;
        padding: 4px !important;
        min-width: 34px !important;
    }

    .calendar-time .select .select-item {
        font-size: 14px !important;
    }

    .btn.clear {
        background-color: #F5F5F5 !important;
        margin-right: 10px;

        svg {
            display: none;
        }
    }

    .btn {
        min-width: 80px;
        margin-top: 15px;
        margin-bottom: 10px;
        background-color: #E01919 !important;
        font-size: 14px !important;
    }
    .buttons_input{
        float: left;
        width: 100%;
        padding: 0 10px 8px 0;
    }
}
.md-drppicker:before, .md-drppicker:after {
    position: relative !important;
}
.md-drppicker td.active,
.md-drppicker td.active:hover,
.md-drppicker td.end-date.in-range {
    background-color: #E01919 !important;
    color: #ffffff !important;
}

.md-drppicker td.in-range {
    background-color: rgba(224, 25, 25, 0.1) !important;
}

.mat-focus-indicator {
    position: inherit;
}

mat-paginator {
    // z-index: -1;
    position: relative;
}

.mdc-floating-label--required::after {
    color: #E01919 !important;
}

table {
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

@media print {
    @page {
        size: 5.0in 6.3in;
        margin: 0; 
    }
    
    body,
    .hide-on-print,
    .cdk-overlay-backdrop,
    mat-dialog-container,
    .mdc-dialog__surface {
        visibility: hidden;
    }

    .section-to-print {
        visibility: visible;
    }

    .mdc-dialog__surface {
        box-shadow: none !important;
    }
}
app-qr-payment-receipt{
    align-items: center;
    justify-content: center;
}
.datepickermenu{
    top: 12px;
    left: 18px;
    max-width: unset !important;
}
hr {
    margin: 24px 0;
    border-bottom-width: 0;
}
.mat-datepicker-toggle .mat-mdc-icon-button{
    color: #777777 !important;
}

.connected-icon{
    width: 12px;
    height: 12px;
}

ngx-material-timepicker-content {
    --button-color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
    --dial-background-color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
    --dial-editable-active-color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
    --clock-hand-color: rgba(var(--fuse-primary-rgb), var(--tw-bg-opacity)) !important;
}
.mat-button-toggle-group{
    flex-wrap: wrap;
}

.mcalendar-card{
    right: -17px;
    top: 12px;
    max-width: 290px !important;
    .mat-calendar {
        min-width: 290px;
    }
    .mat-mdc-form-field-flex{
        padding: 0 8px !important;
    }
    .mat-mdc-menu-content{
        padding: 0;
    }
    .mat-calendar-controls{
        margin: 0;
    }
    .mat-mdc-form-field-infix {
        min-height: 35px !important;
    }
    .mat-mdc-raised-button, .mat-mdc-outlined-button,.mat-mdc-button{
        min-height: 30px;
        min-width: 80px !important;
        padding: 0 5px !important;
        border-radius: 0.25rem !important;
    }
}
.redDisabled{
    label {
        color: inherit !important;
    }
    .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background{
        background-color: #E01919 !important;
    }
} 

.popup-heading {
    top: -24px;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
}

.filterpopup .mdc-dialog__surface{
    border-radius: 0 !important;
    padding: 0 !important;
    background-color: #F5F6FC !important;
}

@media (max-width: 768px) {
    .mainTable {
      table {
        border-collapse: separate;
        border-spacing: 0 10px;
        background-color: inherit !important;
      }

      .mat-mdc-header-row {
        display: none;
      }

      td, .mat-mdc-footer-row td{
        display: block;
        width: 100%;
        border: 0;
        padding: 2px 10px 2px;

        &:first-child {
          display: none;
        }
      }
      .mat-mdc-no-data-row td{
        display: block;
      }
      tr {
        background: #ffffff !important;
      }

      .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
        background-color: #ffffff !important;
      }

      .hide {
        display: none;
      }

      td mat-label {
        font-weight: 600;
        margin-right: 5px;
      }

      .plate {
        height: auto !important;
        width: 100%;
      }
    }
  }

  @media (min-width: 768px) {
    .mainTable {
      td mat-label {
        display: none;
      }
    }
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
    color: inherit !important;
}